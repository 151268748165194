<template>
  <a :href="$host() + '/'">
    <img
      src="~/assets/images/Te/TeCommon/Logo/logo-icon.svg"
      alt="テックアカデミー"
      width="50"
      height="31"
    >
  </a>
</template>
